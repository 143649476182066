@font-face {
	font-family: "EuclidCircularA";
	src: url("../fonts/EuclidCircularA-Bold.woff2") format("woff2"), url("../fonts/EuclidCircularA-Bold.woff") format("woff"), url("../fonts/EuclidCircularA-Bold.ttf") format("truetype"), url("../fonts/EuclidCircularA-Bold.svg#EuclidCircularA") format("svg");
	font-style: normal;
	font-weight: bold;
}
@font-face {
	font-family: "EuclidCircularA";
	src: url("../fonts/EuclidCircularA-Light.woff2") format("woff2"), url("../fonts/EuclidCircularA-Light.woff") format("woff"), url("../fonts/EuclidCircularA-Light.ttf") format("truetype"), url("../fonts/EuclidCircularA-Light.svg#EuclidCircularA") format("svg");
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: "EuclidCircularA";
	src: url("../fonts/EuclidCircularA-Medium.woff2") format("woff2"), url("../fonts/EuclidCircularA-Medium.woff") format("woff"), url("../fonts/EuclidCircularA-Medium.ttf") format("truetype"), url("../fonts/EuclidCircularA-Medium.svg#EuclidCircularA") format("svg");
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: "EuclidCircularA";
	src: url("../fonts/EuclidCircularA-Regular.woff2") format("woff2"), url("../fonts/EuclidCircularA-Regular.woff") format("woff"), url("../fonts/EuclidCircularA-Regular.ttf") format("truetype"), url("../fonts/EuclidCircularA-Regular.svg#EuclidCircularA") format("svg");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "EuclidCircularA";
	src: url("../fonts/EuclidCircularA-SemiBold.woff2") format("woff2"), url("../fonts/EuclidCircularA-SemiBold.woff") format("woff"), url("../fonts/EuclidCircularA-SemiBold.ttf") format("truetype"), url("../fonts/EuclidCircularA-SemiBold.svg#EuclidCircularA") format("svg");
	font-style: normal;
	font-weight: 600;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Black.eot");
	src: url("../fonts/Gilroy-Black.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Black.woff") format("woff"), url("../fonts/Gilroy-Black.ttf") format("truetype");
	font-style: normal;
	font-weight: 900;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Extrabold.eot");
	src: url("../fonts/Gilroy-Extrabold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Extrabold.woff") format("woff"), url("../fonts/Gilroy-Extrabold.ttf") format("truetype");
	font-style: normal;
	font-weight: 800;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Bold.eot");
	src: url("../fonts/Gilroy-Bold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: bold;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Semibold.eot");
	src: url("../fonts/Gilroy-Semibold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Semibold.woff") format("woff"), url("../fonts/Gilroy-Semibold.ttf") format("truetype");
	font-style: normal;
	font-weight: 600;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Medium.eot");
	src: url("../fonts/Gilroy-Medium.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Medium.woff") format("woff"), url("../fonts/Gilroy-Medium.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Regular.eot");
	src: url("../fonts/Gilroy-Regular.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Light.eot");
	src: url("../fonts/Gilroy-Light.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy-Light.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Thin.eot");
	src: url("../fonts/Gilroy-Thin.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Thin.woff") format("woff"), url("../fonts/Gilroy-Thin.ttf") format("truetype");
	font-style: normal;
	font-weight: 200;
}

html,
body {
	width: 100%;
	min-height: 100vh;
}
html.is-hideScroll,
body.is-hideScroll {
	overflow: hidden !important;
	width: 100%;
}

html {
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	overflow-x: hidden;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0;
	padding: 0 0 0 100px;
	line-height: 1.42857143;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.04em;
	font-family: "EuclidCircularA", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 767px) {
	body {
		padding-top: 76px;
		padding-left: 0;
		padding-bottom: 76px;
	}
}
body.is-indent {
	padding-left: 0;
}
body.is-wopadding {
	padding: 0;
}
@media screen and (max-width: 767px) {
	body.is-wopadding {
		padding: 0;
	}
}
body > .main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
}
body > .main--start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
*:before, *:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
	font-size: 100%;
	margin: 0;
}

button,
input {
	overflow: visible;
}

button[disabled] {
	opacity: 0.55;
	cursor: not-allowed;
}

[type=search] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
}

p,
h1, h2, h3, h4, h5, h6,
hr {
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
}
ul li {
	list-style: none;
}

a {
	outline: none;
	text-decoration: none;
}
a:hover, a:focus {
	text-decoration: none;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

picture {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.c-modal {
  z-index: 9992;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.c-modal__bg {
  z-index: 9990;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0, 0.8); */
}

.c-modal__container {
  z-index: 9993;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 35px 13px;
  text-align: center;
  font-size: 0;
	
}

.c-modal__container:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  min-height: 100%;
  height: 100%;
  margin-left: -0.3px;
}

.c-modal__content {
  z-index: 9995;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  max-width: 471px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 26px;
  background-color: #fff;
  font-size: 14px;
  text-align: left;
}

.c-modal#modalTurnIdea .c-modal__close {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-100%);
	display: flex;
	padding: 12px;
	z-index: 2;
}

.c-modal#modalTurnIdea .c-modal__content {
	padding: 0;
}

.c-modal#modalTurnIdea .c-modal__head,
.c-modal#modalTurnIdea .c-modal__body {
	transform: translateY(-36px);
	padding-left: 36px;
	padding-right: 36px;
}
@media screen and (max-width: 767px) {
	.c-modal#modalTurnIdea .c-modal__head,
	.c-modal#modalTurnIdea .c-modal__body {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.c-modal#modalTurnIdea .c-modal__user {
	overflow: hidden;
	position: relative;
	width: 116px;
	height: 116px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 12px;
}
.c-modal#modalTurnIdea .c-modal__user img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
}
.c-modal#modalTurnIdea .c-modal__name {
	padding-bottom: 17px;
	font-family: 'Gilroy', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 22px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #0A0B08;
}
.c-modal#modalTurnIdea .c-modal__title {
	padding-bottom: 17px;
	font-family: 'Gilroy', sans-serif;
	font-weight: 700;
	font-size: 30px;
	line-height: 33px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #0A0B08;
}
.c-modal#modalTurnIdea form {
	display: flex;
	flex-direction: column;
}
.c-modal#modalTurnIdea form label {
	position: relative;
	display: flex;
}
.c-modal#modalTurnIdea form label:not(:last-of-type) {
	margin-bottom: 12px;
}
.c-modal#modalTurnIdea form label input, .c-modal#modalTurnIdea form label select {
	outline: none;
	position: relative;
	display: flex;
	width: 100%;
	height: 54px;
	margin: 0;
	padding: 0 13px;
	border: none;
	border-radius: 12px;
	box-shadow: none;
	background-color: #F5F5F5;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: #000000;

	-webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.c-modal#modalTurnIdea form label input.is-error, .c-modal#modalTurnIdea form label select.is-error {
	border: 1px solid #f1526f;
}

.c-modal#modalTurnIdea form label input + span, .c-modal#modalTurnIdea form label select + span {
	position: absolute;
	top: 50%;
	right: 18px;
	transform: translateY(-50%);
	font-weight: 500;
	font-size: 22px;
	line-height: 37px;
	letter-spacing: -0.03em;
	color: #000000;
	text-shadow: 0px 40.3846px 76.9231px rgba(130, 60, 0, 0.3), 0px 16.8717px 32.1366px rgba(130, 60, 0, 0.215656), 0px 9.02043px 17.1818px rgba(130, 60, 0, 0.178832), 0px 5.05678px 9.63196px rgba(130, 60, 0, 0.15), 0px 2.68562px 5.11546px rgba(130, 60, 0, 0.121168), 0px 1.11755px 2.12866px rgba(130, 60, 0, 0.0843437);

	pointer-events: none
}
.c-modal#modalTurnIdea form label > div.icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	width: 38px;
	height: 34px;
}
.c-modal#modalTurnIdea form label img {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	display: block;
	max-width: 100%;
	height: auto;
}

.c-modal#modalTurnIdea form label img.one {
	border-radius: 50%;
	box-shadow: 0px 4.88113px 9.29739px rgba(130, 60, 0, 0.3), 0px 2.03922px 3.88423px rgba(130, 60, 0, 0.215656), 0px 1.09026px 2.07669px rgba(130, 60, 0, 0.178832), 0px 0.611193px 1.16418px rgba(130, 60, 0, 0.15), 0px 0.3246px 0.618286px rgba(130, 60, 0, 0.121168), 0px 0.135073px 0.257283px rgba(130, 60, 0, 0.0843437), inset 0px 0.348652px 0.0581087px rgba(255, 255, 255, 0.33), inset 0px -0.348652px 0.0581087px rgba(0, 0, 0, 0.17);
}
.c-modal#modalTurnIdea form label img.two {
	box-shadow: 0px 8.4px 16px rgba(130, 60, 0, 0.3), 0px 3.50932px 6.68442px rgba(130, 60, 0, 0.215656), 0px 1.87625px 3.57381px rgba(130, 60, 0, 0.178832), 0px 1.05181px 2.00345px rgba(130, 60, 0, 0.15), 0px 0.558609px 1.06402px rgba(130, 60, 0, 0.121168), 0px 0.23245px 0.442761px rgba(130, 60, 0, 0.0843437), inset 0px 1.4px 4.1px #FFFFFF, inset 0px -0.8px 3.7px rgba(150, 113, 69, 0.45);
	border-radius: 8px;
}

.c-modal#modalTurnIdea form label img.three {
	box-shadow: 0px 8.4px 16px rgba(130, 60, 0, 0.3), 0px 3.50932px 6.68442px rgba(130, 60, 0, 0.215656), 0px 1.87625px 3.57381px rgba(130, 60, 0, 0.178832), 0px 1.05181px 2.00345px rgba(130, 60, 0, 0.15), 0px 0.558609px 1.06402px rgba(130, 60, 0, 0.121168), 0px 0.23245px 0.442761px rgba(130, 60, 0, 0.0843437), inset 0px 1.4px 4.1px #FFFFFF, inset 0px -0.8px 3.7px rgba(150, 113, 69, 0.45);
  border-radius: 4px;
	width: 24px;
}	

.c-modal#modalTurnIdea form label img + i {
	position: absolute;
	top: -10px;
	right: -2px;
	font-weight: 500;
	font-size: 22px;
	line-height: 37px;
	letter-spacing: -0.03em;
	color: #EC2C2C;
	text-shadow: 0px 40.3846px 76.9231px rgba(130, 60, 0, 0.3), 0px 16.8717px 32.1366px rgba(130, 60, 0, 0.215656), 0px 9.02043px 17.1818px rgba(130, 60, 0, 0.178832), 0px 5.05678px 9.63196px rgba(130, 60, 0, 0.15), 0px 2.68562px 5.11546px rgba(130, 60, 0, 0.121168), 0px 1.11755px 2.12866px rgba(130, 60, 0, 0.0843437);
	transform: rotate(-30deg);
}
.c-modal#modalTurnIdea form label + p {
	margin-bottom: 12px;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	text-align: right;
	letter-spacing: -0.03em;
	color: #000000;
}
.c-modal#modalTurnIdea form button {
	outline: none;
	cursor: pointer;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	height: 76px;
	margin-top: 12px;
	border-radius: 20px;
	border: 2px solid #000;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.179714), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.149027), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.125), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.100973), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0702864);
	background-color: #000000;
	font-family: 'Gilroy', sans-serif;
	font-weight: bold;
	font-size: 26px;
	line-height: 21px;
	text-align: center;
	letter-spacing: -0.04em;
	color: #FFFFFF;
	transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.c-modal#modalTurnIdea form button:hover {
	background-color: #3C3C3C;
	transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
.c-modal#modalTurnIdea form button[disabled] {
	pointer-events: none;
	box-shadow: none;
	border-color: #969696;
	background-color: #969696;
	color: rgba(255, 255, 255, 0.3);
	transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

/* ====== */

.dot-flashing-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff3;
  color: #fff3;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff3;
  color: #fff3;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff3;
  color: #fff3;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #fff3;
  }
  50%,
  100% {
    background-color: #fff;
  }
}

/* ====== */

.lds-dual-ring {
	position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
	top: 18px;
	left: 18px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #eee;
  border-color: #eee transparent #eee transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ====== */

.iti {
	width: 100%;
}

.iti-mobile .iti--container {
	z-index: 9999 !important;
}